<template>
	<v-dialog persistent v-model="dialog" width="800px">
		<v-card style="padding:0 24px;">
			<v-card-title>
				{{title}}
			</v-card-title>
			<v-card-text style="margin-top:20px;">
				<v-form :readonly="readonly" ref="ssform">
					<v-row dense>
						<v-col cols="12">
							<v-text-field label="诊断" clearable dense :rules="textRules" placeholder="请填写诊断" v-model="according">
								<template v-slot:append>
									<v-tooltip bottom>
										<template v-slot:activator="{ on }">
											<v-icon v-on="on" @click.stop="onappend">
												content_paste
											</v-icon>
										</template>
										从报告的诊断部分粘贴
									</v-tooltip>
								</template>
							</v-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6"><v-select dense label="胃镜复诊周期(月)" :items="[3,6,12,24,36,60,120]" v-model="periodw" clearable/></v-col>
						<v-col cols="6"><v-select dense label="肠镜复诊周期(月)" :items="[3,6,12,24,36,60,120]" v-model="periodc" clearable/></v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6"><v-text-field label="建议1(可不填)" clearable dense v-model="tx"/></v-col>
						<v-col cols="6"><v-text-field label="建议2(可不填)" clearable dense v-model="exam"/></v-col>
					</v-row>
				</v-form>
			</v-card-text>
			<v-card-text style="border:1px solid #ccc;border-radius:5px;">
				<div id="printme4ss">
					<div style="display:flex;justify-content:space-between;">
						<div>
							<img src='/huasanlogo1.png' width="200"/>
						</div>
						<div style="display:flex;align-items:center;">
							<img src='/QR.png' width="60" height="60"/>
						</div>
						<!--
						<div style="font-size:14px;">
							<div style="margin-top:56px;" >
								<span>编号</span><span class="valuecontent">{{item && item._id}}</span>
							</div>
							<div style="margin-top:12px;" >
								<span>日期</span><span class="valuecontent">{{s2}}</span>
							</div>
						</div>
						-->
					</div>
					<hr style="border:none;height:1px;color:#ce5925;background-color:#ce5925;transform:scaleY(0.5);"/>
					<div style="margin-top:48px;text-align:center;font-size:28px;font-weight:bold;" >
						{{title}}
					</div>
					<div style="margin-top:64px;" >
						<div>尊敬的{{s1}}，</div>
						<div style="margin-top:24px;margin-left:36px;">您于{{s2}}在华三消化内镜中心{{item && item.region}}诊所行{{item && item.opts.GIE}}检查。</div>
						<div style="margin-top:12px;">根据本次检查{{according}}　我们建议您</div>
						<div style="margin-top:12px;" v-if="s4">{{s4}}</div>
						<div style="margin-top:12px;" v-if="s5">{{s5}}</div>
						<div style="margin-top:12px;" v-if="!!tx">{{`● ${tx}`}}</div>
						<div style="margin-top:12px;" v-if="!!exam">{{`● ${exam}`}}</div>
						<!--<div style="margin-top:12px;">{{mode === 0 ? '' : '本复诊单只显示18个月以内的复诊提醒。'}}如果您对上述建议有任何问题，请联系您的健康顾问预约医生咨询。</div>-->
						<div style="margin-top:108px;margin-right:36px;text-align:right;font-weight:bold;">华三消化内镜中心{{item && item.region}}诊所</div>
						<div style="margin-top:16px;margin-right:36px;text-align:right;font-weight:bold;">{{s2}}</div>
						<div style="margin-top:16px;text-align:right;opacity:0.02;">{{now}}</div> <!--水印-->
					</div>
					<div style="position:absolute;left:0;bottom:60px;width:100%;text-align:center;">
						<div style="color:#999;font-style:italic;font-size:16px;margin-bottom:48px;">本次复查建议根据您最近一次在本诊所的内镜报告给出，如复查期前出现不适需及时就医。</div>
						<img src='/slogan.png' width="400"/>
					</div>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer/>
				<v-btn color="primary" v-print="printObj" @click.stop="setNow" :loading="saving" :disabled="saving||!according">{{readonly ? '仅生成' : '保存并生成'}}</v-btn>
				<v-btn @click.stop="exitDlg">返回</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	import print from 'vue-print-nb'
	import {formatDate, formatTime, addMonths} from '../utils'

	export default {
		name: 'Bill',
		directives: {
			print   
		},
		props: {
			mode: 0,
			value: false,
			item: {},
			readonly: true
		},
		data() {
			return {
				textRules: [v => !!v || '此项为必填'],
				according: '诊断',
				periodw: null,
				periodc: null,
				tx: '',
				exam: '',
				saving: false,
				now: '',
				printObj: {
					id: 'printme4ss',
					popTitle: '复诊单',
					beforeOpenCallback(vue) {
						return false;
					},
					openCallback(vue) {
						vue.save();
					}
				}
			}
		},
		computed: {
			dialog: {
				get() {
					return this.value;
				},
				set(v) {
					if (!v) {
						this.$emit('input', false);
					}
				}
			},
			title() {
				return this.mode === 0 ? '复 诊 提 醒' : '复 诊 单';
			},
			s1() {
				if (!this.item) return '';
				return this.item.pii.username + (this.item.pii.gender === '男' ? '先生' : '女士');
			},
			s2() {
				if (!this.item) return '';
				return formatDate(this.item.progress.operative);
			},
			s4() {
				if (!this.item) return null;
				const dw = this.periodw ? formatDate(addMonths(this.periodw, this.item.progress.operative)) : null;
				if (this.periodw && this.periodc && this.periodw === this.periodc) {
					return `● 在${dw}前进行胃肠镜复查`;
				}
				if (this.periodw) {
					return `● 在${dw}前进行胃镜复查`;
				}
				return null;
			},
			s5() {
				if (!this.item) return null;
				const dc = this.periodc ? formatDate(addMonths(this.periodc, this.item.progress.operative)) : null;
				if (this.periodw && this.periodc && this.periodw === this.periodc) return null;
				if (this.periodc) {
					return `● 在${dc}前进行肠镜复查`;
				}
			}
		},
		watch: {
			value(v) {
				if (v) {
					let s;
					if (this.item && this.item.subsequent) {
						s = this.item.subsequent;
					} else {
						s = this.$options.data();
					}
					this.according = s.according;
					this.periodw = s.periodw;
					this.periodc = s.periodc;
					this.tx = s.tx;
					this.exam = s.exam;
				}
			}
		},
		methods: {
			exitDlg() {
				this.dialog = false;
				this.$emit('close');
			},
			setNow() {
				this.now = `[ ${formatTime(new Date())} ]`;
			},
			async save() {
				if (this.readonly) return;  //只打印，不保存
				if (!this.$refs.ssform.validate()) return;
                this.saving = true;
				const id = this.item._id;
				const estTimew = this.periodw ? addMonths(this.periodw, this.item.progress.operative) : undefined;
				const estTimec = this.periodc ? addMonths(this.periodc, this.item.progress.operative) : undefined;
				const subsequent = {
					according: this.according,
					periodw: this.periodw,
					periodc: this.periodc,
					tx: this.tx,
					exam: this.exam,
					estTimew,
					estTimec,
				}
				try {
					await this.$tcbapp.callFunction({name:"wp2mp", data:{funcname:'issueSubsequent', data:{id, subsequent}}});
				} catch(err) {
					console.error(err);				
				}
				this.saving = false;
			},
			/*
			pasteFromMr() {
				if (this.item.MedicalHistory && this.item.MedicalHistory.length > 0) {
					const mrs = this.item.MedicalHistory[this.item.MedicalHistory.length-1].mrs;
					if (mrs && mrs['诊断']) {
						this.according = mrs['诊断'];
						return;
					}
				}
				this.$dialog.message.error('没有符合条件的记录');
			},
			*/
			async onappend() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				try {
					const res = await db.collection('wp2order').doc(this.item._id).field({reports:true}).get();
					const rpt = res.data[0]?.reports;
					if (rpt) {
						let rs = '';
						if (rpt['胃镜']?.diagnoses) rs += rpt['胃镜']?.diagnoses + '\n';
						if (rpt['肠镜']?.diagnoses) rs += rpt['肠镜']?.diagnoses;
						this.according = rs;
					} else {
						this.$dialog.message.warning('无法获取报告内容');
					}
				} catch(err) {
					console.error(err);
				}
			}
		}
	}
</script>

<style>
#printme4ss {
	position:relative;
	height:1040px;
	font-size:18px;
	padding:24px 24px;
}
.valuecontent {
	display:inline-block;
	width:200px;
	border-bottom: 1px solid black;
	text-align:left;
	margin-left:8px;
	padding-left:8px;
}
</style>
